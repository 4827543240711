import QuoteStatus from '@/config/QuoteStatus.json';

let QuoteStatusMap:any = undefined;

export default ():{'退回':number; '拒絕':number; '送出':number; '同意':number; '攔截':number; '待審核':number;} => {
    if (QuoteStatusMap !== undefined) {
        return QuoteStatusMap;
    } else {
        QuoteStatusMap = {};
        for (const type of QuoteStatus) {
            QuoteStatusMap[type.value] = type.text;
        }
        return QuoteStatusMap;
    }
}