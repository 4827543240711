
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component

    export default class CustomTable extends Vue{
        // props
        @Prop() fields!:{key:string; label:string}[];
        @Prop() datas!:any[];
        @Prop() queryRows!:number;
        @Prop() totalRows!:number;
        @Prop() isSelectAll!:boolean;
        @Prop({default:false}) isBusy?:boolean;
        @Prop({default:false}) isSelectable?:boolean;
        @Prop({default:'multi'}) selectMode?:string;
        @Prop({default:20}) perPage!:number;
        @Prop() isEditable!:boolean;
        @Prop() needPage!:boolean;

        // data
        currentPage = 1;
        selected = [];

        // hook
        beforeUpdate():void{
            const currentRows = ((this.currentPage - 1) * this.perPage);
            if (currentRows >= this.queryRows && !this.isBusy && this.totalRows > this.queryRows) {
                this.$emit("dataRequire", currentRows, this.perPage);
            }            
        }

        // methods
        onRowSelected(items:any):void{
            this.selected = items;
            this.$emit("rowSelected", this.selected);
        }
        onRowClick(items:any, index:number):void{
            this.$emit("rowClick", items, index);
        }
        toFirstPage():void{
            this.currentPage = 1;
        }
    }
