export function convertDateToFormattedText(start:Date, end:Date):string{
    const startYear = start.getFullYear()
    const endYear = end.getFullYear()
    const startMonth = start.getMonth() + 1
    const endMonth = end.getMonth() + 1
    const startDay = start.getDate()
    const endDay = end.getDate()

    if (startYear === endYear) {
        return `${startYear} ${startMonth}/${startDay} ~ ${endMonth}/${endDay}`
    } else if (startYear !== endYear) {
        return `${startYear} ${startMonth}/${startDay} ~ ${endYear} ${endMonth}/${endDay}`
    } else {
        return "N/A"
    }
}

export function convertDateObject2YYYYMMDD(dateObj:Date):string{
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const date = dateObj.getDate().toString().padStart(2, '0');

    if(typeof dateObj === 'object'){
        return `${year}/${month}/${date}`;
    }else{
        return 'N/A';
    }
}

export function convertDateObject2YYYY_MM_DD_HH_MM_SS(dateObj:Date):string{
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const date = dateObj.getDate().toString().padStart(2, '0');
    const hour = dateObj.getHours().toString().padStart(2, '0');
    const minute = dateObj.getMinutes().toString().padStart(2, '0');
    const second = dateObj.getSeconds().toString().padStart(2, '0');

    if(typeof dateObj === 'object'){
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
    }else{
        return 'N/A';
    }
}

export function dateConvertorForRawQueryData(str:string):string{
    return str.replaceAll('T', ' ').replace(/\.\d{3}/g, '');
}