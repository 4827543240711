
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component

    export default class SimpleModal extends Vue{
        // props
       @Prop() order!:any[];
       @Prop({default:false}) isPdf!:boolean;
       @Prop({default:''}) formErrorMessage!:string;
       @Prop({default:'Simple-Modal'}) id!:string;
       @Prop({default:''}) title!:string;
       @Prop({default:false}) hideFooter!:boolean;
       @Prop({default:'lg'}) size!:string;
       @Prop({default:false}) isLoading!:boolean;

        // data
        orderArr:any[] =  [];
        isShow:null | boolean = null;

        // hook
        mounted():void{
            this.orderArr = this.order;
        }

        // methods
        onSaveClick():void{
            this.$emit("onSaveClick");
        }
        resetModal():void{
            this.$emit("resetModal");
        }
        hideModal():void{
            this.$emit("hideModal");
        }
    }
