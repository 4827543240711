
    import { Component, Prop, Vue } from "vue-property-decorator";

    import SimpleModal from '@/components/Modal/Util/SimpleModal.vue';

    @Component({
        components:{
            SimpleModal
        }
    })

    export default class DatepickerModal extends Vue{
        // props
       @Prop({default:''}) idKey!:string;

        // data
        dateRange = {
            start:'',
            end:''
        }


        // hook

        // methods
        getDateRange():void{
            const start = this.dateRange.start;
            const end =  this.dateRange.end;
     
            if(start == '') {
              alert('請填寫起始日'); 
              return;  
            }else if(end == '') {
              alert('請填寫結束日'); 
              return;  
            }else if(start.localeCompare(end)==1) {
              alert('結束日不得小於起始日'); 
              return;  
            } 
            this.$emit('sendDateRange', this.dateRange);
            this.$bvModal.hide(`datepicker-modal-${this.idKey}`);
        }
    }
