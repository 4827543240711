
import { Component, Vue } from "vue-property-decorator";
import QuotationHeader from "@/components/Quotation/QuotationHeader.vue";
import CustomTable from "@/components/Table/CustomTable.vue";
import WorkingCategory from "../../config/WorkingCategory.json";
import DatepickerModal from "@/components/Modal/Util/DatepickerModal.vue";
import * as DI from "@/utility/DependencyInjector";
import QuoteStatusMap from "@/model/Mapping/QuoteStatusMap";
import SimpleModal from "@/components/Modal/Util/SimpleModal.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { convertDateToFormattedText } from "@/model/Date/Date";
// import this to use default icons for buttons
import AddComma from "../../models/MoneyFormat/AddComma";
import { Buffer } from "buffer";

@Component({
  components: {
    QuotationHeader,
    CustomTable,
    DatepickerModal,
    VuePdfEmbed,
    SimpleModal,
  },
})
export default class QuotationList extends Vue {
  //data
  addComma = AddComma;
  queryRows = 0;
  totalRows = 0;
  isLoading = true;
  haveQuotation = true;
  canAddQuotation = false;
  isCompelted = false;
  cantAddQuotation = false;
  repairOrderId = this.$route.query.repairOrderId;
  orderAdditionalFlag: number;
  address = "無地址";
  clientName = "無客戶名稱";
  Item: {
    status: number;
    masterIncomeOfferPrice: string;
    offerPrice: string;
    expectElapsedDay: string;
    expectElapsedTime: string;
  }[] = [];
  status: number;
  masterIncome = 0;
  finalPrice = 0;
  expectElapsedDays = 0;
  expectElapsedTimes = 0;
  data = "";
  quotationUrl = "";
  workingCategoryOption: { value: string; text: string }[] = WorkingCategory;
  search: { [key: string]: string } = {};
  searchDateRange = "N/A";
  QuoteStatusMap = QuoteStatusMap();
  quotationDetail: { id: number }[] = [];
  count = 0;
  //for pdf
  pdfArray: Array<unknown> = [];
  page: any = null;
  pageData?: any;
  pageCount = 0;
  renderCount = 0;
  //monted
  mounted(): void {
    this.getList();
  }
  //methods
  getDateRange(data: { start: string; end: string }): void {
    this.searchDateRange = convertDateToFormattedText(
      new Date(data.start),
      new Date(data.end)
    );
    this.search.createDate_start = data.start;
    this.search.createDate_end = data.end;
  }

  getStatusColor(value: number): string {
    if (value === 0 || value === -1) {
      return "#FF2851";
    }
    if (value === 2 || value === 3 || value === 4) {
      return "#2ECC71";
    }
    if (value === 1) {
      return "#3A89F8";
    }
    return `"black"`; // Default color for unknown status
  }

  async getList(): Promise<void> {
    try {
      this.isLoading = true;
      const res = await DI.get("App").repairOrder.get(this.repairOrderId);
      if(res.status >= 70){
        this.$router.push(
      `/Quotation/List`
    );
      }
      this.address = res.address;
      this.clientName = res.clientUserObscureName;
      this.status = res.status;
      this.orderAdditionalFlag = res.orderAdditionalFlag;
      this.isCompelted = this.status === 60;
      const statusJudge = this.status < 40 || this.status > 50;
      if (!this.isCompelted) {
        const flagJudge =
          (this.orderAdditionalFlag & 8) == 0 &&
          (this.orderAdditionalFlag & 32) == 0;
        if (statusJudge && flagJudge) {
          this.canAddQuotation = true;
          this.cantAddQuotation = false;
        } else {
          this.canAddQuotation = false;
          this.cantAddQuotation = true;
        }
      }

      const res1 = await DI.get("App").quotation.list({
        repairOrderId: this.repairOrderId,
      });
      if (res1.data && res1.data.length > 0) {
        this.haveQuotation = true;
        this.quotationDetail = res1.data;
        res1.data.forEach((item: any) => {
          if (item.status > -1 && item.status < 4) {
            this.masterIncome += item.masterIncomeOfferPrice;
            this.finalPrice += item.taxedOfferPrice;
            this.expectElapsedDays += item.expectElapsedDay;
            this.expectElapsedTimes += item.expectElapsedTime;
          }
        });

        //time format
        if (this.expectElapsedTimes >= 24) {
          this.expectElapsedDays += Math.floor(this.expectElapsedTimes / 24);
          this.expectElapsedTimes = this.expectElapsedTimes % 24;
        }
        this.isLoading = false;
      } else {
        this.haveQuotation = false;
        this.isLoading = false;
      }
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.haveQuotation = false;
      this.isLoading = false;
    }
  }
  async requestPdf(index: number): Promise<void> {
    this.pageCount = 0;
    if (!this.pdfArray[index]) {
      const jsonString = JSON.stringify(this.quotationDetail[index]);
      const bufferObj = Buffer.from(jsonString, "utf8");
      const quotationB64 = bufferObj.toString("base64");
      const d = await DI.get("App").quotation.detailGeneratePdf(
        this.repairOrderId,
        quotationB64
      );
      const pdfUrl = URL.createObjectURL(d);
      this.pdfArray[index] = pdfUrl;
      this.count++;
    }
  }
  truncateAddressWithRegex(address: string): string {
    const roadTypes = ["路", "街", "道", "區", "鄉", "市"];
    let roadName = "";
    if (!address) {
      return address;
    }
    for (let type of roadTypes) {
      const regex = new RegExp(`.*[${type}]`);
      const match = address.match(regex);
      if (match) {
        roadName = match[0];
        break;
      }
    }
    return roadName ? roadName : address;
  }
  clickAdd(): void {
    this.$router.push(
      `/Quotation/Standard?repairOrderId=${this.repairOrderId}`
    );
  }

  handleDocumentRender(): void {
    this.isLoading = false;
    this.pageData = this.$refs.pdfRef;
    this.pageCount = this.pageData[0].pageCount;
    this.renderCount++;
  }
}
